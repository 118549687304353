import { useLocale, useTranslations } from 'next-intl'
import { LetsTalk } from './LetsTalk'

export type ContactInfoProps = {
  phone: string
  email: string
  textColor: string
  highlightColor: string
}

export function ContactInfo(props: ContactInfoProps) {
  const t = useTranslations('common')

  return (
    <address className="not-italic relative">
      <ul className="leading-33 md:leading-26 whitespace-nowrap">
        <li>
          <a
            href={`tel:${props.phone}`}
            aria-label={t('callUsAt', { phoneNumber: props.phone })}
          >
            {props.phone}
          </a>
        </li>
        <li>
          <a
            href={`mailto:${props.email}`}
            aria-label={t('emailUsAt', { emailAddress: props.email })}
          >
            {props.email}
          </a>
        </li>
      </ul>

      <Scribble highlightColor={props.highlightColor} />
    </address>
  )
}

function Scribble({ highlightColor }: { highlightColor: string }) {
  const locale = useLocale()
  const className =
    'absolute top-[1.5rem] md:top-[1rem] pointer-events-none left-[-0.75rem]'

  switch (locale) {
    case 'de':
      return <LetsTalk className={className} highlightColor={highlightColor} />

    case 'en':
      return <LetsTalk className={className} highlightColor={highlightColor} />

    default:
      return null
  }
}
